// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Videos from "../../blocks/videos/src/Videos";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";


const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Videos:{
 component:Videos,
path:"/Videos"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;